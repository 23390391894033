<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="50%"
    @close="close"
  >
    <vab-query-form>
      <vab-query-form-left-panel :span="0"></vab-query-form-left-panel>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryFormExh" @submit.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryFormExh.likeName"
              clearable
              placeholder="请输入会议名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        align="center"
        label="展会名称"
        prop="name"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="地址"
        prop="address"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="开始时间"
        prop="startTimeStr"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="结束时间"
        prop="endTimeStr"
        show-overflow-tooltip
      />
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryFormExh.pageNum"
      :layout="layout"
      :page-sizes="queryFormExh.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { getAllMeetByEnterprise } from '@/api/workBench'

  export default defineComponent({
    name: 'enterpriseByMeet',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        eventList: [],
        title: '',
        dialogFormVisible: false,
        dialogVisible: false,
        dialogImageUrl: '',
        list: [],
        layout: 'prev, pager, next,total,sizes',
        total: 0,
        pageSize: [10, 20, 30, 50, 100],
        listLoading: true,
        queryFormExh: {
          enterpriseCode: '',
          pageNum: 1,
          pageSize: 10,
          likeName: '',
        },
      })

      const showEdit = async (row) => {
        state.queryFormExh.enterpriseCode = row.enterpriseCode
        fetchData()
        state.dialogFormVisible = true
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getAllMeetByEnterprise(state.queryFormExh)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      }

      const queryData = async () => {
        state.queryFormExh.pageNum = 1
        fetchData()
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const handleSizeChange = (val) => {
        state.queryFormExh.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryFormExh.pageNum = val
        fetchData()
      }

      return {
        ...toRefs(state),
        showEdit,
        close,
        queryData,
        handleSizeChange,
        handleCurrentChange,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
